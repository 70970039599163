(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
'use strict';

jQuery(function ($) {

  $(window).on('load', function () {
    // temp - set placeholder with JS
    $('#user_login').attr('placeholder', 'Username or Email');
    $('#user_pass').attr('placeholder', 'Password');

    // temp - hacky JS to fix the markup
    var login_form_html = $('#loginform').html(),
        br_regex = /<br\s*[\/]?>/gi,
        label_close_regex = /<\/label>/g;
    $('#loginform').html(login_form_html.replace(label_close_regex, ''));
    $('#loginform').html(login_form_html.replace(br_regex, '</label>'));

    var wrap = $('#loginform p'),
        field = wrap.find('input');

    toggleFilled(field);

    field.on('keyup focus blur', function () {
      toggleFilled($(this));
    });
  });
});

function toggleFilled(field) {
  var wrap = field.parents('#loginform p');
  if (field.val().length > 0) {
    wrap.addClass('js--filled');
  } else {
    wrap.removeClass('js--filled');
  }
}

},{}]},{},[1]);
